"use client";

import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { Suspense } from "react";
import { Layout } from "~/components/v2/Login/Layout";

export default function PageNotFound() {
  return (
    <Suspense>
      <Layout>
        <div className="p-[40px]">
          <ExclamationCircleIcon className="mx-auto mb-4 h-20 w-20 text-red-400" />
          <h1 className="mb-[16px] text-center text-[20px] font-semibold leading-7 text-black">
            Oops, There was a problem.
          </h1>
          <p className="mb-[16px] text-center text-[14px] font-semibold leading-tight text-black">
            There was problem to load this page, try to reload again or go to
            our home page.
          </p>
          <Link
            className="mt-[16px] inline-flex h-10 w-full items-center justify-center gap-[7px] rounded-xl bg-[#0020D3] px-4 text-base font-semibold leading-normal text-white"
            href="/"
          >
            SCALIS Home
          </Link>
        </div>
      </Layout>
    </Suspense>
  );
}
